@media only screen and (max-width: 767px) {
  .container {
    margin-top: 6.25rem;
  }
  .cta {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.75rem;
    row-gap: 0.75rem;
  }
  .headline {
    width: 100%;
  }
  .subtitle {
    width: 100%;
  }
  .cases {
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 3.125rem;
    row-gap: 3.125rem;
    justify-content: center;
    align-items: center;
  }
  .embla__container {
    column-gap: 1.875rem;
    display: grid;
    margin-left: 0;
    margin-right: 0;
    row-gap: 1.75rem;
  }
  .button {
    font-size: 0.938rem;
    height: 2.625rem;
    line-height: 1.063rem;
    width: 100%;
  }
  .card {
    margin: auto;
    background-color: var(--color-illustrations-black-bg);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    min-height: 28.75rem;
    min-width: 20.25rem;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .card-content {
    padding: 0 0.75rem;
  }
  .card__description {
    margin-top: 0.625rem;
  }
  .card__footer {
    align-items: center;
    color: var(--color-text-secondary);
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 1.5rem 0.75rem;
    --color-text-main: var(--color-text-secondary);
  }
  .eye-icon {
    --icon-height: 1.5rem;
    margin-left: auto;
    margin-right: 0.75rem;
    --icon-width: 1.5rem;
  }
  .card__image {
    height: 14.25rem !important;
    max-width: 100%;
    object-fit: cover;
    position: relative !important;
    /* width: auto; */
  }
  .card__headline {
    font-size: 1rem;
    line-height: 1.25rem;
    margin-top: 1.5rem;
  }
  .card__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 1.5rem;
    row-gap: 0.375rem;
  }
  /* .card[data-idx="2"] {
    display: none;
  } */
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .container {
    margin-top: 10rem;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;
    row-gap: 1.5rem;
  }
  .headline {
    width: 80%;
  }
  .subtitle {
    width: 50%;
  }
  .cases {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20.5rem, 1fr));
    grid-template-rows: 1fr;
    row-gap: 3.75rem;
    column-gap: 1.875rem;
    margin-top: 3.75rem;
  }
  .embla__container {
    column-gap: 1.875rem;
    display: flex;
  }
  .button {
    height: 3.375rem;
    width: 17.625rem;
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
  }
  .card {
    background-color: var(--color-illustrations-black-bg);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    min-height: 31.875rem;
    min-width: 20.625rem;
    /* max-width: 20.625rem; */
    overflow: hidden;
    position: relative;
  }
  .card-content {
    padding: 1.5rem;
    padding-bottom: 0;
  }
  .card__description {
    margin-top: 0.625rem;
  }
  .card__footer {
    align-items: center;
    color: var(--color-text-secondary);
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 1.5rem;
    --color-text-main: var(--color-text-secondary);
  }
  .eye-icon {
    --icon-height: 1.5rem;
    margin-left: auto;
    margin-right: 0.75rem;
    --icon-width: 1.5rem;
  }
  .card__image {
    height: 14.188rem !important;
    min-height: 14.188rem !important;
    max-width: 100% !important;
    object-fit: cover;
    object-position: left bottom;
    position: relative !important;
    position: relative !important;
    /* width: auto !important; */
  }
  .card__headline {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .card__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 1.5rem;
    row-gap: 0.375rem;
  }
  /* .card[data-idx="2"] {
    display: none;
  } */
}
@media only screen and (min-width: 1440px) {
  .container {
    margin-top: 10rem;
  }
  .cta {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
  }
  .headline {
    width: 50%;
  }
  .subtitle {
    width: 50%;
  }
  .cases {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20.5rem, 1fr));
    grid-template-rows: 1fr;
    row-gap: 3.75rem;
    column-gap: 1.875rem;
    margin-top: 3.125rem;
  }
  .embla__container {
    column-gap: 1.875rem;
    display: flex;
  }
  .button {
    height: 3.25rem;
    width: 17.625rem;
    margin-bottom: 0.55rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
  .card {
    background-color: var(--color-illustrations-black-bg);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column;
    min-height: 31.875rem;
    min-width: 21.875rem;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .card-content {
    padding: 1.5rem;
    padding-bottom: 0;
  }
  .card__description {
    margin-top: 0.625rem;
  }
  .card__footer {
    align-items: center;
    color: var(--color-text-secondary);
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding: 1.5rem;
    --color-text-main: var(--color-text-secondary);
  }
  .eye-icon {
    --icon-height: 1.5rem;
    margin-left: auto;
    margin-right: 0.75rem;
    --icon-width: 1.5rem;
  }
  .card__image {
    height: 14.188rem !important;
    min-height: 14.188rem !important;
    max-width: 100% !important;
    object-fit: cover;
    position: relative !important;
    /* width: auto !important; */
  }
  .card__headline {
    font-size: 1.125rem;
    line-height: 1.5rem;
  }
  .card__list {
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 1.5rem;
    row-gap: 0.375rem;
  }
}
