@media only screen and (max-width: 767px) {
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.75rem;
    row-gap: 0.75rem;
  }
  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-color: var(--color-brand-white);
    --button-hover-opacity: 1;
    --button-hover-background-color: var(--color-illustrations-black-bg);

    font-size: 0.938rem;
    height: 2.625rem;
    width: 100%;
    line-height: 1.063rem;
  }
  .headline {
    width: 100%;
  }
  .descriprion {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
    width: 100%;
  }
  .catches {
    align-items: flex-start;
    background-color: var(--color-brand-white);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: column-reverse;
    height: 26.825rem;
    min-height: 26.825rem;
    justify-content: flex-end;
    margin-top: 3.125rem;
    padding: 1.5rem;
  }
  .experience {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    row-gap: 0;
    width: 100%;
  }
  .experience__item {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: -0.25rem;
  }
  .experience__header {
    color: var(--color-text-accent);
    font-size: 1.75rem;
    line-height: 2rem;
  }
  .experience__subheader {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
  }
  .steps {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    width: 100%;
  }
  .progress-container {
    align-items: center;
    background-color: var(--color-illustrations-light-gray);
    display: flex;
    height: 0.188rem;
    justify-content: center;
    margin: 0.75rem -0.3rem 2rem;
    margin-top: 0.5rem;
    position: relative;
    width: 98%;
    --width-multiplier: 1.04;
  }
  .progress-bar {
    background-color: var(--color-text-accent);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width 0.2s;
  }
  .checkpoint {
    background-color: var(--color-brand-white);
    border-radius: 50%;
    height: 1.25rem;
    position: absolute;
    width: 1.25rem;
  }
  .checkpoint[data-reached="true"]::before {
    background-color: var(--color-text-accent);
  }
  .checkpoint::before {
    background-color: var(--color-illustrations-light-gray);
    border-radius: 50%;
    content: "";
    height: 0.75rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
  }
  .checkpoint-label {
    display: none;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-left: 0.25rem;
    position: absolute;
    top: 1.25rem;
  }
  .step-text {
    display: flex;
    flex-direction: column;
    margin-bottom: 1.4rem;
    margin-top: 0;
    row-gap: 0.75rem;
  }
  .buttons {
    display: flex;
    margin-bottom: auto;
    width: 100%;
    --button-font-size: 1rem;
    --button-line-height: 1rem;
    --icon-width: 0.7rem;
    --icon-height: 0.7rem;
  }
  .button-next {
    display: flex;
    background-color: transparent;
    color: var(--color-text-accent);
    margin-right: 0.75rem;
    margin-right: 0.4rem;
    column-gap: 0.5rem;
    margin-left: auto;
    padding: 0;
    width: fit-content;
    --button-hover-color: var(--color-text-accent);
    --button-hover-opacity: 0.7;
    --button-hover-background-color: transparent;
    --button-active-opacity: 0.9;
    --button-active-background-color: transparent;
  }
  .button-prev {
    display: flex;
    background-color: transparent;
    color: var(--color-text-accent);
    margin-right: -0.4rem;
    margin-right: auto;
    column-gap: 0.5rem;
    padding: 0;
    width: fit-content;
    --button-hover-color: var(--color-text-accent);
    --button-hover-opacity: 0.7;
    --button-hover-background-color: transparent;
    --button-active-opacity: 0.9;
    --button-active-background-color: transparent;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;
    row-gap: 1.5rem;
  }
  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-opacity: 1;
    --button-hover-color: var(--color-brand-white);
    --button-hover-background-color: var(--color-illustrations-black-bg);
    --button-icon-width: 0.8rem;
    --button-icon-height: 0.8rem;
    height: 3.375rem;
    width: 17.625rem;
  }
  .headline {
    width: 68%;
  }
  .descriprion {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
    width: 75%;
  }
  .catches {
    align-items: flex-start;
    background-color: var(--color-brand-white);
    border-radius: 1.25rem;
    display: flex;
    flex-direction: row-reverse;
    height: 24.25rem;
    justify-content: space-between;
    margin-top: 3.75rem;
    padding: 2.5rem;
  }
  .experience {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-top: -0.7rem;
    row-gap: 2.5rem;
    width: auto;
  }
  .experience__item {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 0.75rem;
  }
  .experience__header {
    color: var(--color-text-accent);
    font-size: 2.75rem;
    line-height: 3.125rem;
  }
  .experience__subheader {
    font-size: 0.938rem;
    line-height: 1.063rem;
  }
  .steps {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-top: 0.185rem;
    margin-left: -0.25rem;
    justify-content: flex-start;
    width: 67%;
  }
  .progress-container {
    align-items: center;
    background-color: var(--color-illustrations-light-gray);
    display: flex;
    height: 0.1rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 0.75rem;
    position: relative;
    width: 97%;
    --width-multiplier: 1.06;
  }
  .progress-bar {
    background-color: var(--color-text-accent);
    max-width: 100%;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width 0.2s;
  }
  .checkpoint {
    background-color: var(--color-brand-white);
    border-radius: 50%;
    height: 1.25rem;
    position: absolute;
    width: 1.25rem;
  }
  .checkpoint[data-reached="true"]::before {
    background-color: var(--color-text-accent);
  }
  .checkpoint::before {
    background-color: var(--color-illustrations-light-gray);
    border-radius: 50%;
    content: "";
    height: 0.75rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
  }
  .checkpoint-label {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-left: 0.25rem;
    position: absolute;
    top: 1.5rem;
  }
  .step-text {
    display: flex;
    flex-direction: column;
    margin-top: 2.5rem;
    margin-left: 0.25rem;
    row-gap: 0.75rem;
    width: 97%;
  }
  .buttons {
    display: flex;
    width: 100%;
    margin-bottom: 1rem;
    margin-top: auto;
    --button-font-size: 1rem;
    --button-line-height: 1rem;
    --icon-width: 0.7rem;
    --icon-height: 0.7rem;
  }
  .button-next {
    display: flex;
    margin-right: 0.5rem;
    column-gap: 0.5rem;
    background-color: transparent;
    color: var(--color-text-accent);
    margin-left: auto;
    margin-top: auto;
    padding: 0;
    --button-hover-color: var(--color-text-accent);
    --button-hover-opacity: 0.7;
    --button-hover-background-color: transparent;
    --button-active-opacity: 0.9;
    --button-active-background-color: transparent;
  }
  .button-prev {
    display: flex;
    margin-right: -0.5rem;
    column-gap: 0.5rem;
    background-color: transparent;
    color: var(--color-text-accent);
    margin-right: auto;
    margin-top: auto;
    padding: 0;
    --button-hover-color: var(--color-text-accent);
    --button-hover-opacity: 0.7;
    --button-hover-background-color: transparent;
    --button-active-opacity: 0.9;
    --button-active-background-color: transparent;
  }
}
@media only screen and (min-width: 1440px) {
  .cta {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
  }
  .button {
    --button-border-color: var(--color-button-tertiary-bg);
    --button-color: var(--button-outline-text-black);
    --button-hover-opacity: 1;
    --button-hover-color: var(--color-brand-white);
    --button-hover-background-color: var(--color-illustrations-black-bg);
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
    height: 3.375rem;
    width: 17.625rem;
  }
  .headline {
    width: 42%;
  }
  .descriprion {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    row-gap: 1.875rem;
    width: 47%;
    margin-top: 0.75rem;
    margin-right: 1rem;
  }
  .catches {
    align-items: flex-start;
    background-color: var(--color-brand-white);
    border-radius: 1.25rem;
    display: flex;
    height: 21.25rem;
    justify-content: space-between;
    margin-top: 3.75rem;
    padding: 1.75rem 2.5rem;
  }
  .experience {
    align-items: flex-start;
    display: flex;
    column-gap: 3rem;
    width: 40%;
  }
  .experience__item {
    display: flex;
    flex-direction: column;
  }
  .experience__header {
    color: var(--color-text-accent);
    font-size: 2.75rem;
    line-height: 3.125rem;
  }
  .experience__subheader {
    font-size: 0.938rem;
    line-height: 1.063rem;
    margin-top: 0.75rem;
  }
  .steps {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
    width: 49%;
  }
  .progress-container {
    align-items: center;
    background-color: var(--color-illustrations-light-gray);
    display: flex;
    height: 0.188rem;
    justify-content: center;
    margin-bottom: 2rem;
    margin-top: 1rem;
    position: relative;
    width: 97%;
    --width-multiplier: 1.07;
  }
  .progress-bar {
    background-color: var(--color-text-accent);
    height: 100%;
    max-width: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: width 0.2s;
  }
  .checkpoint {
    background-color: var(--color-brand-white);
    border-radius: 50%;
    height: 1.25rem;
    position: absolute;
    width: 1.25rem;
  }
  .checkpoint[data-reached="true"]::before {
    background-color: var(--color-text-accent);
  }
  .checkpoint::before {
    background-color: var(--color-illustrations-light-gray);
    border-radius: 50%;
    content: "";
    height: 0.75rem;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 0.75rem;
  }
  .checkpoint-label {
    font-size: 0.875rem;
    line-height: 1rem;
    margin-left: 0.25rem;
    position: absolute;
    top: 1.25rem;
  }
  .step-text {
    display: flex;
    flex-direction: column;
    margin-left: 0.125rem;
    margin-top: 2.125rem;
    row-gap: 0.75rem;
  }
  .buttons {
    display: flex;
    margin-left: -0.5rem;
    margin-bottom: 0.875rem;
    margin-top: auto;
    width: 100%;
    --button-font-size: 1rem;
    --button-line-height: 1rem;
    --icon-width: 0.7rem;
    --icon-height: 0.7rem;
  }
  .button-next {
    display: flex;
    margin-right: -0.5rem;
    column-gap: 0.5rem;
    background-color: transparent;
    color: var(--color-text-accent);
    margin-left: auto;
    margin-top: auto;
    padding: 0;
    width: fit-content;
    --button-hover-color: var(--color-text-accent);
    --button-hover-opacity: 0.7;
    --button-hover-background-color: transparent;
    --button-active-opacity: 0.9;
    --button-active-background-color: transparent;
  }
  .button-prev {
    display: flex;
    margin-right: -0.5rem;
    column-gap: 0.5rem;
    background-color: transparent;
    color: var(--color-text-accent);
    margin-right: auto;
    margin-top: auto;
    padding: 0;
    width: fit-content;
    --button-hover-color: var(--color-text-accent);
    --button-hover-opacity: 0.7;
    --button-hover-background-color: transparent;
    --button-active-opacity: 0.9;
    --button-active-background-color: transparent;
  }
}
