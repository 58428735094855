@media only screen and (max-width: 767px) {
  .container {
    margin-top: 5.5rem;
  }
  .cta {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;
    row-gap: 1.5rem;
  }
  .headline {
    width: 100%;
  }
  .cta__button {
    height: 2.5rem;
    --button-font-size: 0.938rem;
    --button-line-height: 1.063rem;
  }
  .cases {
    column-gap: 1.875rem;
    display: grid;
    grid-template-columns: 1fr;
    margin-top: 3.125rem;
    row-gap: 3rem;
    justify-content: center;
    align-items: center;
  }
  .case {
    display: flex;
    flex-direction: column;
  }
  .card {
    background: transparent;
    border-radius: 0.625rem;
    height: 12.5rem;
    overflow: hidden;
    position: relative;
    min-width: 20.25rem;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    right: 0;
    row-gap: 1.5rem;
    top: 0;
  }
  .card-hover__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .card-hover__content__single {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .card-hover__text {
    font-size: 1.25rem;
    line-height: 1.625rem;
    row-gap: 1.5rem;
    display: flex;
    flex-direction: column;
  }
  .card-hover__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 2fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.5rem;
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .card-hover__content__single {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .card-hover__text-small {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card-hover__text-big {
    font-size: 2.375rem;
    margin-right: 1.25rem;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    text-wrap: nowrap;
  }
  .card-hover__button {
    --button-background-color: var(--icon-button-default-background);
    bottom: 1.25rem;
    height: 3.125rem;
    position: absolute;
    right: 1.25rem;
    width: 3.125rem;
    --icon-height: 1rem;
    --icon-width: 1rem;
  }
  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.125rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }
  .button {
    font-size: 0.938rem;
    height: 2.625rem;
    line-height: 1.063rem;
  }
  .show-more-button {
    font-size: 0.938rem;
    height: 2.625rem;
    line-height: 1.063rem;
    margin-top: 1.875rem;
    width: 100%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 1439px) {
  .container {
    margin-top: 11.125rem;
  }
  .cta {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0.5rem;
    row-gap: 1.875rem;
  }
  .headline {
    width: 80%;
  }
  .cases {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20.5rem, 1fr));
    grid-template-rows: 1fr;
    margin-top: 4rem;
    row-gap: 3.75rem;
    column-gap: 1.875rem;
  }
  .case {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    min-width: 20.625rem;
  }
  .card {
    background: transparent;
    border-radius: 1rem;
    height: 12.5rem;
    overflow: hidden;
    position: relative;
    min-width: 20.625rem;
    width: 100%;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    right: 0;
    row-gap: 1.5rem;
    top: 0;
  }
  .card-hover__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .card-hover__content__single {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
  .card-hover__text-small {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card-hover__text-big {
    font-size: 2.375rem;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    text-wrap: nowrap;
  }
  .card-hover__button {
    --button-background-color: var(--icon-button-default-background);
    bottom: 1.25rem;
    height: 3.125rem;
    position: absolute;
    right: 1.25rem;
    width: 3.125rem;
    --icon-height: 1rem;
    --icon-width: 1rem;
  }

  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.55rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }

  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-top: -0.5rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
}
@media only screen and (min-width: 1440px) {
  .container {
    margin-top: 12.5rem;
  }
  .cta {
    align-items: flex-end;
    display: flex;
    justify-content: space-between;
    margin-top: 0.75rem;
  }
  .headline {
    width: 50%;
  }
  .cases {
    column-gap: 1.75rem;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-top: 3.125rem;
    row-gap: 4rem;
  }
  .card {
    background: transparent;
    border-radius: 1rem;
    height: 12.5rem;
    overflow: hidden;
    position: relative;
    width: 21.875rem;
  }
  .card-image {
    object-fit: cover;
    opacity: 0.6;
  }
  .card:hover .card-hover {
    display: flex;
  }
  .card-hover {
    background: var(--gradient-pink-purple-default);
    bottom: 0;
    display: none;
    align-items: center;
    justify-content: flex-start;
    left: 0;
    position: absolute;
    right: 0;
    column-gap: 1rem;
    top: 0;
  }
  .card-hover__content {
    display: grid;
    grid-template-columns: min-content minmax(0, 3fr);
    grid-template-rows: 1fr;
    grid-column-gap: 0.75rem;
    grid-row-gap: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .card-hover__content__single {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
  }
  .card-hover__text-small {
    font-size: 1rem;
    line-height: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .card-hover__text-big {
    font-size: 2.375rem;
    display: flex;
    align-items: baseline;
    justify-content: flex-end;
    text-wrap: nowrap;
  }
  .card-hover__button {
    bottom: 1.25rem;
    position: absolute;
    right: 1.25rem;

    --button-background-color: var(--icon-button-default-background);
    --button-height: 3.125rem;
    --button-width: 3.125rem;
    --button-icon-height: 1rem;
    --button-icon-width: 1rem;
  }

  .badge {
    backdrop-filter: blur(4px);
    background: var(--color-button-tertiary-bg-transparent);
    border-radius: 3.75rem;
    font-size: 0.875rem;
    left: 1.25rem;
    line-height: 1rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
    top: 1.25rem;
    width: fit-content;
  }
  .case__headline {
    margin-top: 1.5rem;
  }
  .case__description {
    margin-top: 0.5rem;
  }

  .cta__button {
    line-height: 1.063rem;
    height: 3.25rem;
    width: 17.575rem;
    margin-bottom: 0.6rem;
    --button-icon-height: 0.8rem;
    --button-icon-width: 0.8rem;
  }
}
